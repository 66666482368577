import axios from "axios";
import {lang} from "./lang";
import {LangLocalization, PackParams, SubClasses, UsersInterface} from "../interface";

/* ROUTES */
export const HOME_ROUTE = '/';
export const LOG_ROUTE = '/log';
export const PROFILE_ROUTE = '/profile';
export const RECOVERY_ROUTE = '/password/:token';
export const USERS_ROUTE = '/users';
export const SEARCH_ROUTE = '/search';
export const AMB_PRIEM_ROUTE = '/outpatient_appointment';
export const AMB_COMMON_ROUTE = '/outpatient_appointment/common';
export const AMB_PASSING_ROUTE = '/outpatient_appointment/passing/:caseId';
export const SMART_CENTER_ROUTE = '/smart_center';
export const RESULT_ROUTE: string = '/smart_center/result';
export const EXAM_ROUTE: string = '/smart_center/examination';
export const ADMIN_ROUTE: string = '/smart_center/admin';
export const ADMIN_USER_ROUTE: string = '/smart_center/admin/:userId';
export const LICENSE_ROUTE: string = '/smart_center/license';
export const STATIONS_ROUTE: string = '/smart_center/stations';
export const NURSING_ROUTE: string = '/nursing';
export const NURSING_COMMON_ROUTE: string = '/nursing/common';


export const API_SOCKET_URl = process.env.NODE_ENV === 'production' && !process.env.TEST ? 'wss://case-manager.pro:5010'
    : process.env.TEST ? 'ws://89.111.131.145:5010/' : 'ws://192.168.0.198:5010';
export const API_URl = process.env.NODE_ENV === 'production' && !process.env.TEST ? 'https://case-manager.pro/'
    : process.env.TEST ? 'http://89.111.131.145/' : 'http://192.168.0.198/';
export const API_IMAGES_URL = API_URl + 'images/';
export const API_DIALOGUE_AUDIO_URL = API_URl + 'dialogue_audio/';
export const API_INSPECTION_AUDIO_URL = API_URl + 'inspection_audio/';
export const API_INSPECTION_IMAGE_URL = API_URl + 'inspection_image/';
export const API_NURSING_DIALOGUE_AUDIO_URL = API_URl + 'nursing_dialogue_audio/';
export const API_NURSING_INVESTIGATION_IMAGE_URL = API_URl + 'investigation_image/';
export const API_NURSING_CASE_DATA_IMAGE_URL = API_URl + 'case_data_image/';
export const API_NURSING_CASE_FLOWCHART_URL = API_URl + 'case_flowchart/';
/* END-ROUTES */

export const PROJECT_NAME = "CM"
export const SMART_CENTER = "SC"
export const VARIANT_BUTTON = 'outline-primary';

export const LANGUAGES = ['ru', 'en', 'tr', 'es', 'ar', 'de', 'pt', 'fr', 'zh', 'ko', 'ja', 'th', 'he', 'zh_tw', 'fil', 'id'];

export const DEFAULT_PACK_ID = {
    original: '6228902ced5066ebe88c9657',
    international: '631b2df6818135e66849c6ae'
};

//Для проверки на валидность email
export const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

//объект дефолтных значений
export const DEFAULT_LANGUAGES_VALUES: { [key: string]: string } = {
    ru: '(Введите значение)',
    en: '(Enter value)',
    tr: '(Değer girin)',
    es: '(Introduzca un valor)',
    ar: '(أدخل قيمة)',
    de: '(Wert eingeben)',
    pt: '(Insira um valor)',
    fr: '(Entrez une valeur)',
    zh: '(输入值)',
    ko: '(값 입력)',
    ja: '(値を入力してください)',
    th: '(ใส่ค่า)',
    he: '(הזן ערך)',
    zh_tw: '(輸入值)',
    fil: '(Ilagay ang halaga)',
    id: '(Masukkan nilai)'
}

//объект дефолтных значений
export const DEFAULT_EMPTY_LANGUAGES_VALUES: { [key: string]: string } = {
    ru: '',
    en: '',
    tr: '',
    es: '',
    ar: '',
    de: '',
    pt: '',
    fr: '',
    zh: '',
    ko: '',
    ja: '',
    th: '',
    he: '',
    zh_tw: '',
    fil: '',
    id: ''
}

//Вкладки кейсов
export const CASE_TABS = {
    PASSPORT: '62305feb751ed51287c669de',
    DIALOGUE: '62306067751ed51287c669df',
    INSPECTION: '623060c7751ed51287c669e0',
    EXAMINATION: '6231bf9027b7881d036b57d7',
    DIAGNOSIS: '623060ef751ed51287c669e1',
    TREATMENT: '62306194751ed51287c669e2'
}

//Имена пунктов фильтра
export const LOG_NAMES_FILTER = ['category', 'user', 'date', 'std', 'verify', 'curCase', 'pack', 'prevValue', 'newValue', 'lang', 'info']

//Голоса синтеза речи
export const SYNTHESIZE_VOICE: { [key: string]: Array<{ name: string, gender: string }> } = {
    ru: [
        {name: 'Vladimir_n', gender: 'M'},
        {name: 'Julia_n', gender: 'F'},
        {name: 'Julia_8000n', gender: 'F'},
        {name: 'Maria_n', gender: 'F'},
        {name: 'Mikhail_n', gender: 'M'},
        {name: 'Galina_n', gender: 'F'},
        {name: 'Dasha_n', gender: 'F'},
        {name: 'Petr_n', gender: 'M'},
        {name: 'Dasha14_8000n', gender: 'F'},
        {name: 'Dasha_8000n', gender: 'F'},
        {name: 'Alexander_n', gender: 'M'},
        {name: 'Victoria_n', gender: 'F'},
        {name: 'Vladimir_8000n', gender: 'M'},
        {name: 'Anna_8000n', gender: 'F'},
        {name: 'Anna_n', gender: 'F'},
        {name: 'Dasha_8000n_fast', gender: 'F'}
    ]
}

//Список для контекстного меню
export const CONTEXT_MENU_LIST: { [key: string]: Array<{ [key: string]: string }> } = {
    category: [
        {
            lang: lang.info.outpatient_65,
            action: 'add'
        },
        {
            lang: lang.info.core_22,
            action: 'edit'
        },
        {
            lang: lang.info.core_18,
            action: 'delete'
        }
    ],
    case: [
        {
            lang: lang.info.core_52,
            action: 'copy'
        },
        {
            lang: lang.info.core_22,
            action: 'edit'
        },
        {
            lang: lang.info.core_18,
            action: 'delete'
        }
    ],
    table: [
        {
            lang: lang.info.core_18,
            action: 'delete'
        }
    ],
    logs: [
        /*{
            lang: lang.info.log_11,
            action: 'recovery'
        },*/
        {
            lang: lang.info.core_46,
            action: 'refresh'
        }
    ]
}

//селекты у препаратов в лечении
export const TREATMENT_MEDICINE_OPTIONS: Array<{ type: string, name: LangLocalization }> = [
    {
        type: 'form',
        name: lang.info.outpatient_35
    },
    {
        type: 'injection',
        name: lang.info.outpatient_36
    },
    {
        type: 'dose',
        name: lang.info.core_16
    },
    {
        type: 'frequency',
        name: lang.info.outpatient_37
    },
    {
        type: 'duration',
        name: lang.info.outpatient_38
    },
    {
        type: 'condition',
        name: lang.info.outpatient_39
    }
];

/*
* Проверка есть ли нужное свойство обьекта(нужная локализация).
* Если нет, то устанавливаеться дефолт - пустая строка.
*/
export const checkLang = (obj: { [key: string]: string }, lang: string) => {
    if (obj[lang] === undefined) obj[lang] = '';
}

/*
* @param obj - объект
*
* Функция проверка пустой ли объект
*/
export function isEmpty(obj: object) {
    for (const key in obj) // если тело цикла начнет выполняться - значит в объекте есть свойства
        return false;
    return true;
}


/*
* Определение к какому типу ошибка относиться.
*/
export const detectedError = (e: any) => {
    if (e instanceof ErrorEvent) {
        console.log(e.message);
    } else if (axios.isAxiosError(e)) {
        console.log(e.response.data);
        return e.response.data;
    } else {
        console.log(e);
    }
}
/*
* @param value - строка(число)
* @param highLevel - верхний уровень числа(Не включительно)
* @param minLevel - нижний уровень числа(Не включительно)
*
* Проверка соответсвует ли полученое число рамкам(highLevel, minLevel).
* Возвращает число или объект с текстом ошибки на разных языках
*/
export const CheckValidScore = (value: string, highLevel: number, minLevel: number): number | any => {
    if (value) {
        if (!isNaN(parseInt(value))) {
            if (parseInt(value) > highLevel || parseInt(value) < minLevel)
                return lang.errors.core_3;
            else {
                return parseInt(value);
            }
        } else return lang.errors.core_2;
    } else return lang.errors.core_6;
}

/*
* @param currentAudio - текущая аудиозапись
* @param newAudio - новая выбранная аудиозапись
* @param e - событие
*
* Обнуляет предыдущую запись(ставит паузу, время на начало, удаляет id)
* Запускает новую аудиозапись
*/
export const playAudio = (currentAudio: string, newAudio: string, tab: string, e: any) => {
    const startPlay = () => {
        e.currentTarget.parentNode.firstChild.id = newAudio;
        e.currentTarget.parentNode.firstChild.src = (tab === 'dialogue' ? API_DIALOGUE_AUDIO_URL : API_INSPECTION_AUDIO_URL) + newAudio;
        e.currentTarget.parentNode.firstChild.play();
    }

    const player: any = document.getElementById(currentAudio);
    if (player === null) {
        return startPlay();
    }
    if (currentAudio === newAudio) {
        player.pause();
        player.currentTime = 0.0;
        player.play();
        return;
    }
    player.pause();
    player.currentTime = 0.0;
    player.id = '';
    player.src = '';
    startPlay();
}

/*
* @param windowId - id окна параметров препаратов или имен
* @param elemId - id имени или препарата, который выбрали
*
* Цикл проходит по всем именам(или препаратам)
* Находит по elemId определенное имя
* Выводит параметры этого имени
*/
export const showListWindow = (windowId: string, elemId?: string) => {
    const list = document.getElementById(windowId);
    if (list) {
        for (let i = 0; i < list.children.length; i++) {
            const child: any = list.children[i];
            if (child.id.indexOf(elemId) !== -1) {
                if (child.style.display === 'flex') {
                    child.style.display = 'none';
                } else {
                    child.style.display = 'flex';
                }
            } else {
                if (child.style.display === 'flex') {
                    child.style.display = 'none';
                }
            }
        }
    }
}

/*
* @param el - объект текста
* @param lang - текущий язык
* @param listLang - все возможные языки
*
* Проверяет текст на содержание дефолтных значений и существование на англ языке
* Если нет англ языка, ищет первый язык имеющий текст
* Если этот текст не из дефолтных значений, присваивается переменной с аббревиатурой языка
*/
interface useNameReturnValue {
    name: string
    color: string
}

interface useNameParams {
    el: LangLocalization
    lang: string
    listLang: Array<string>
}

export const useName = ({el, lang, listLang}: useNameParams): useNameReturnValue => {
    let name = el[lang];
    let color = '#000000';
    if (!name || name === '') {
        if (listLang.indexOf('en') !== -1 && el.en) {
            name = 'en: ' + el.en;
            color = '#FF6060FF';
        } else {
            for (let i = 0; i < listLang.length; ++i) {
                if (el[listLang[i]]) {
                    name = listLang[i] + ': ' + el[listLang[i]];
                    color = '#FF6060FF';
                    return {name, color};
                }
            }
        }
    }

    return {name, color};
}

export function flagEdit(curCase: SubClasses, curUser: UsersInterface, text: boolean = false, lang: string = '') {
    if (curUser.role === 'admin') return true;

    if (curCase.type === 'custom') {
        const currentDate = new Date().getTime();

        const differenceInMillis = currentDate - curCase.createDate;

        const differenceInYears = differenceInMillis / (1000 * 60 * 60 * 24 * 365);

        if (differenceInYears >= 1) {
            return false;
        }
    }

    if (curUser.role === 'user') {
        return !curUser.banEdit;
    }

    if (curUser.role === 'client') {
        return curCase.type !== 'base' && !curUser.banEdit;
    }

    if (text) {
        return curUser.role === lang;
    }

    return false;
}

export function flagNursingEdit(curCase: SubClasses, curUser: UsersInterface, text: boolean = false, lang: string = '') {
    if (curUser.role === 'admin') return true;

    if (curUser.role === 'user') {
        return !curUser.nursingBanEdit;
    }

    if (curUser.role === 'client') {
        return curCase.type !== 'base' && !curUser.nursingBanEdit;
    }

    if (text) {
        return curUser.role === lang;
    }

    return false;
}

/*
* @param str - текст
*
* Перевод в аперкейс первой буквы текста
*/
export function ucFirst(str: string) {
    str = str.trim();
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}

export function getDefaultPackId() {
    // @ts-ignore
    return DEFAULT_PACK_ID[localStorage.getItem('std')];
}

/*
* @param clientX и clientY- координаты указателя
* @param type - тип для контекстного меню
*
* Считает помещается ли контекстное меню, где был произведен клик.
*/
export function countPixel(clientX: number, clientY: number, type: string) {
    let x: number | string, y: number | string;
    if ((clientY + 10 + 40 * CONTEXT_MENU_LIST[type].length) > document.documentElement.clientHeight) {
        y = (clientY - ((clientY + 10 + 40 * CONTEXT_MENU_LIST[type].length) - document.documentElement.clientHeight)).toString() + 'px';
    } else {
        y = (clientY).toString() + 'px';
    }

    if ((clientX + 250) > document.documentElement.clientWidth) {
        x = (clientX - ((clientX + 250) - document.documentElement.clientWidth)).toString() + 'px';
    } else {
        x = (clientX).toString() + 'px';
    }

    return {x, y};
}

export function checkVerifyCase(id: string, cases: Array<SubClasses> = [], lang: string = undefined) {
    const selectCase = cases.find((el: SubClasses) => el._id === id);

    if (selectCase) {
        if (lang) {
            return selectCase.verify[lang];
        } else {
            for (const lang in selectCase.verify) {
                if (selectCase.verify[lang]) {
                    return true;
                }
            }
        }
    }

    return false;
}

export function checkVerifyNursingCase(cs: any, lang?: string) {
    if (!cs?.verify) return false;

    if (lang) {
        return cs.verify[lang];
    } else {
        for (const lang in cs.verify) {
            if (cs.verify[lang]) {
                return true;
            }
        }
    }

    return false;
}

export function convertFloatNumber(value: string, positive?: boolean) {
    const num = parseFloat(String(value).replace(',', '.'));

    if (!Number.isFinite(num) || (positive && num < 0)) {
        return -1;
    }

    return num;
}

export function convertIntNumber(value: string, positive?: boolean) {
    const num = parseInt(value);

    if (!Number.isFinite(num) || (positive && num < 0)) {
        return -1;
    }

    return num;
}

export const WAY_OPTIONS: Record<string, string[]> = {
    'bolus': ['Peripheral', 'Central'],
    'syringe_pump': ['Peripheral', 'Central'],
    'infusion_pump': ['Peripheral', 'Central'],
    'drip': ['Peripheral', 'Central'],
    'tablet': ['Oral', 'Sublingual']
}

export const packageVoiceText: { [key: string]: string } = {
    ru: 'Чувствую себя хорошо, ничего не беспокоит.',
    en: 'I feel good, nothing bothers me.',
    tr: 'Ben iyi hissediyorum, hiçbir şey beni rahatsız etmiyor.',
    es: 'Me siento bien, nada me molesta.',
    ar: 'أشعر بأنني بخير، لا شيء يزعجني.',
    de: 'Mir geht es gut, nichts stört mich.',
    pt: 'Sinto-me bem, nada me incomoda.',
    fr: 'Je me sens bien, rien ne me dérange.',
    zh: '我感觉很好，没有什么困扰我。',
    ko: '나는 기분이 좋아, 아무것도 거슬리지 않아.',
    ja: '私は気分が良い、何も気にならない。',
    th: 'ฉันรู้สึกดี ไม่มีอะไรที่รบกวนฉัน',
    he: 'אני מרגיש טוב, דבר לא מפריע לי.',
    zh_tw: '我感覺很好，沒有什麼困擾我。',
    fil: 'Nakakaramdam ako ng mabuti, walang nakakaabala sa akin.',
    id: 'Saya merasa baik, tidak ada yang mengganggu saya.'
}

export const canOpenCategory = ({pack, categoryId, role}: { pack: PackParams, categoryId: string, role: string }) => {
    if (role === 'admin') return true;

    return pack.categories.indexOf(categoryId) !== -1;
}

export const nameNursing = [
    'MVR Nursing',
    'Nasco',
    'XR-Nurses',
    'XR-Hospital',
]