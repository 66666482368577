import {$api} from "./index";
import {detectedError} from "../Utils";
import axios from "axios";

//Получение категорий
export const getCategory = async () => {
    try {
        const response = await $api.get('api/main/case/category');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getCategoryForProfile = async (std: string) => {
    try {
        const response = await $api.get(`api/main/case/category/profile?std=${std}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getProfileCases = async (std: string) => {
    try {
        const response = await $api.get(`api/main/case/profile?std=${std}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getActiveCases = async () => {
    try {
        const response = await $api.get('api/main/case/active?packId=' + localStorage.getItem('packId'));
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const switchCase = async (params: object) => {
    try {
        const response = await $api.post('api/main/case/switch', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение диалогов
export const getDialogue = async (caseId: string) => {
    try {
        const response = await $api.get('api/main/case/dialogue?caseId=' + caseId);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение вопросов
export const getDialogueQuestions = async () => {
    try {
        const response = await $api.get('api/main/case/dialogue/question');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение доп обслд
export const getExamination = async (caseId: string) => {
    try {
        const response = await $api.get('api/main/case/examination?caseId=' + caseId);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение списка доп обслд
export const getExaminationTabs = async () => {
    try {
        const response = await $api.get('api/main/case/examination/tab');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Создание доп обслд
export const createExaminationTab = async (params: object) => {
    try {
        await $api.post('api/main/case/examination/tab', params);
    } catch (ex) {
        // @ts-ignore
        return ex.response.data;
    }
}

export const getExaminationCategories = async () => {
    try {
        const response = await $api.get('api/main/case/examination/category');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getExaminationTabCommon = async () => {
    try {
        const response = await $api.get('api/main/case/examination/tab/common');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertExaminationCategory = () => {
    $api.post('api/main/case/examination/category').catch(ex => detectedError(ex));
}

export const deleteExaminationCategory = (id: string) => {
    $api.delete('api/main/case/examination/category?id=' + id).catch(ex => detectedError(ex));
}

export const updateExaminationCategory = (params: object) => {
    $api.put('api/main/case/examination/category', params).catch(ex => detectedError(ex));
}

//Обновление доп обслд
export const updateExaminationTab = (params: object) => {
    $api.put('api/main/case/examination/tabs', params).catch(ex => detectedError(ex));
}

export const updateExaminationTabCategory = (params: object) => {
    $api.put('api/main/case/examination/tabs/category', params).catch(ex => detectedError(ex));
}

//Удаление доп обслд
export const delExaminationTab = (id: string, params?: object) => {
    $api.delete('api/main/case/examination/tab?id=' + id, {data: params}).catch(ex => detectedError(ex));
}

//Обновление имени доп обслд
export const updateNameExaminationTab = (params: object) => {
    $api.put('api/main/case/examination/tab', params).catch(ex => detectedError(ex));
}

//Получение диагнозов
export const getDiagnosis = async (caseId: string) => {
    try {
        const response = await $api.get('api/main/case/diagnosis?caseId=' + caseId);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение лечения
export const getTreatment = async (caseId: string) => {
    try {
        const response = await $api.get('api/main/case/treatment?caseId=' + caseId);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertTreatmentMedication = (params: object) => {
    $api.post('api/main/case/treatment/medication', params).catch(ex => detectedError(ex));
}

export const updateTreatmentMedicationName = (params: object) => {
    $api.put('api/main/case/treatment/medication/name', params).catch(ex => detectedError(ex));
}

export const updateTreatmentMedicationRight = (params: object) => {
    $api.put('api/main/case/treatment/medication/right', params).catch(ex => detectedError(ex));
}

export const deleteTreatmentMedication = (id: string, params: object) => {
    $api.delete('api/main/case/treatment/medication?elemId=' + id, {data: params}).catch(ex => detectedError(ex));
}

//Обновление поля замена в лечении
export const updateTreatmentChangeable = (params: object) => {
    $api.put('api/main/case/treatment/changeable', params).catch(ex => detectedError(ex));
}

//Получение госпитализации
export const getTreatmentHospitalization = async () => {
    try {
        const response = await $api.get('api/main/case/treatment/hospitalization');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение диет
export const getTreatmentDiet = async () => {
    try {
        const response = await $api.get('api/main/case/treatment/diet');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const insertTreatmentTab = (params: object) => {
    $api.post('api/main/case/treatment', params).catch(ex => detectedError(ex));
}

//Обновление диет
export const updateTreatmentTabCommon = (params: object) => {
    $api.put('api/main/case/treatment/tab', params).catch(ex => detectedError(ex));
}

export const deleteTreatmentTabCommon = (id: string, params: object) => {
    $api.delete('api/main/case/treatment/tab?elemId=' + id, {data: params}).catch(ex => detectedError(ex));
}

//Получение кратности приема
export const getTreatmentFrequency = async () => {
    try {
        const response = await $api.get('api/main/case/treatment/frequency');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение продолжительности приема
export const getTreatmentDuration = async () => {
    try {
        const response = await $api.get('api/main/case/treatment/duration');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение путь введения
export const getTreatmentInjection = async () => {
    try {
        const response = await $api.get('api/main/case/treatment/injection');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение особые условия
export const getTreatmentCondition = async () => {
    try {
        const response = await $api.get('api/main/case/treatment/condition');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение формы
export const getTreatmentForm = async () => {
    try {
        const response = await $api.get('api/main/case/treatment/form');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//получение препаратов
export const getTreatmentMedicine = async (flag?: boolean) => {
    try {
        if (flag) {
            const response = await $api.get('api/main/case/treatment/medicine?flag=true');
            return response.data;
        } else {
            const response = await $api.get('api/main/case/treatment/medicine');
            return response.data;
        }
    } catch (ex) {
        detectedError(ex);
    }
}

//получение категорий препаратов
export const getTreatmentCategory = async () => {
    try {
        const response = await $api.get('api/main/case/treatment/category');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//получение данных паспорта
export const getCaseById = async (id: string) => {
    try {
        const response = await $api.get('api/main/case/' + id);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//Получение основных вкладок
export const getCasesTabs = async () => {
    try {
        const response = await $api.get('api/main/case/tab');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//получение работы
export const getJobs = async () => {
    try {
        const response = await $api.get('api/main/case/job');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//получение имен
export const getPassportCaseNames = async () => {
    try {
        const response = await $api.get('api/main/case/passport/name');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getRace = async () => {
    try {
        const response = await $api.get('api/main/case/race');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//удаление имени
export const deleteCaseNames = (id: string, params: object) => {
    $api.delete('api/main/case/name?id=' + id, {data: params}).catch(ex => detectedError(ex));
}

//получение вкладок осмотра
export const getInspection = async () => {
    try {
        const response = await $api.get('api/main/case/review');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//измение описания вкладок осмотра
export const updateInspection = async (params: object) => {
    try {
        await $api.put('api/main/case/review', params);
    } catch (ex) {
        detectedError(ex);
    }
}

//получение вариантов виз.осмотра
export const getVisualPatientCloth = async () => {
    try {
        const response = await $api.get('api/main/case/review/visual/patientCloth');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//выбор варианта виз.осмотра
export const updateVisualPatientCloth = (params: object) => {
    $api.put('api/main/case/review/visual/patientCloth', params).catch(ex => detectedError(ex));
}

//добавление/удаление варианта виз.осмотра
export const updateCountVisualPatientCloth = (params: object) => {
    $api.put('api/main/case/review/visual/patientCloth/count', params).catch(ex => detectedError(ex));
}

//получение перкуссии
export const getPercussion = async (id: string) => {
    try {
        const response = await $api.get('api/main/case/review/percussion?caseId=' + id);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//получение аускультации
export const getAuscultation = async (id: string) => {
    try {
        const response = await $api.get('api/main/case/review/auscultation?caseId=' + id);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//получение пальпации
export const getPalpation = async (id: string) => {
    try {
        const response = await $api.get('api/main/case/review/palpation?caseId=' + id);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//получение виз.осмотра
export const getVisual = async (id: string) => {
    try {
        const response = await $api.get('api/main/case/review/visual?caseId=' + id);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//получение инструментов
export const getInstruments = async (id: string) => {
    try {
        const response = await $api.get('api/main/case/review/instruments?caseId=' + id);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

//создание перкуссии
export const insertPercussion = async (params: object) => {
    try {
        await $api.post('api/main/case/review/percussion', params);
    } catch (ex) {
        detectedError(ex);
    }
}

//создание аускультации
export const insertAuscultation = async (params: object) => {
    try {
        await $api.post('api/main/case/review/auscultation', params);
    } catch (ex) {
        detectedError(ex);
    }
}

//создание пальпации
export const insertPalpation = async (params: object) => {
    try {
        await $api.post('api/main/case/review/palpation', params);
    } catch (ex) {
        detectedError(ex);
    }
}

//создание виз.осмотра
export const insertVisual = async (params: object) => {
    try {
        await $api.post('api/main/case/review/visual', params);
    } catch (ex) {
        detectedError(ex);
    }
}

//создание инструмента
export const insertInstruments = async (params: object) => {
    try {
        await $api.post('api/main/case/review/instruments', params);
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateSelectImageInstruments = async (params: object) => {
    try {
        await $api.put('api/main/case/review/instruments/image/select', params);
    } catch (ex) {
        detectedError(ex);
    }
}

//генерация синтеза речи для нескольких ответов
export const generationAudioPackage = (params: object, flag: boolean) => {
    if (flag) $api.post('api/main/media/upload/generation/package', params).catch(ex => detectedError(ex));
    else $api.post('api/main/synthesize/package', params).catch(ex => detectedError(ex));
}

//генерация синтеза речи
export const generationAudioOld = (params: object) => {
    $api.post('api/main/synthesize', params).catch(ex => detectedError(ex));
}

//загрузка аудио
export const uploadFile = async (params: object) => {
    try {
        await $api.post('api/main/media/upload', params, {headers: {'Content-Type': 'multipart/form-data'}});
    } catch (e) {
        if (axios.isAxiosError(e)) {
            return e.response.data.error;
        } else {
            console.log(e)
        }
    }
}

export const replaceFile = async (params: object) => {
    try {
        await $api.post('api/main/media/replace', params, {headers: {'Content-Type': 'multipart/form-data'}});
    } catch (e) {
        if (axios.isAxiosError(e)) {
            return e.response.data.error;
        } else {
            console.log(e)
        }
    }
}

export const insertAudioGeneration = (params: object) => {
    $api.post('api/main/media/upload/generation', params).catch(ex => detectedError(ex));
}

//использование сущ. аудио в перкуссии
export const insertPercussionAudioExists = (params: object) => {
    $api.post('api/main/case/review/percussion/audio/exists', params).catch(ex => detectedError(ex));
}

export const insertInstrumentsImageExists = (params: object) => {
    $api.post('api/main/case/review/instruments/image/exists', params).catch(ex => detectedError(ex));
}

//использование сущ. аудио в аускультации
export const insertAuscultationAudioExists = (params: object) => {
    $api.post('api/main/case/review/auscultation/audio/exists', params).catch(ex => detectedError(ex));
}

//загрузка фото
export const insertPhoto = (params: object) => {
    $api.put('api/main/case/photo', params).catch(ex => detectedError(ex));
}

//копирование вкладки из выбранного кейса
export const copyCaseTab = (params: object) => {
    $api.post('api/main/case/copy/tab', params).catch(ex => detectedError(ex));
}

//вставка сущ.изображения в доп.обследование
export const insertExaminationPhotoExists = (params: object) => {
    $api.post('api/main/case/examination/photo/exists', params).catch(ex => detectedError(ex));
}

//удаление изображения из доп.обследования
export const deleteExaminationPhoto = (id: string, params: object) => {
    $api.delete('api/main/case/examination/photo?id=' + id, {data: params})
        .catch(ex => detectedError(ex));
}

//создание имени
export const insertCaseNames = () => {
    $api.post('api/main/case/name').catch(ex => detectedError(ex))
}

//изменение имени
export const updateCaseNames = (params: object) => {
    $api.put('api/main/case/name', params).catch(ex => detectedError(ex));
}

export const updateCaseNameOptions = (params: object) => {
    $api.put('api/main/case/name/options', params).catch(ex => detectedError(ex));
}

//создание работы
export const insertJob = (params: object) => {
    $api.post('api/main/case/job', params).catch(ex => detectedError(ex));
}

//удаление работы
export const deleteJob = (id: string, params: object) => {
    $api.delete('api/main/case/job?id=' + id, {data: params}).catch(ex => detectedError(ex));
}

//изменение имени
export const updateJobName = (params: object) => {
    $api.put('api/main/case/job/name', params).catch(ex => detectedError(ex));
}

//копирование кейса
export const copyCase = async (params: object) => {
    try {
        const response = await $api.post('api/main/case/copy', params);
        return response.data;
    } catch (ex: any) {
        if (ex && ex.response && ex.response.data && ex.response.data.error) {
            if (ex.response.data.error === 'Case already exist' || ex.response.data.error === 'Exceeded the limit on cases.') return ex.response.data.error;
        }
        detectedError(ex);
    }
}

//обновление верификации
export const updateCaseVerify = (params: object) => {
    $api.put('api/main/case/verify', params).catch(ex => detectedError(ex));
}

//создание категории кейсов
export const insertCategory = async (params: object) => {
    try {
        await $api.post('api/main/case/category', params);
    } catch (e: any) {
        return e;
    }
}

export const insertDialogue = (params: object) => {
    $api.post('api/main/case/dialogue', params).catch(ex => detectedError(ex));
}

export const insertDiagnosis = async (params: object) => {
    try {
        await $api.post('api/main/case/diagnosis', params);
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteCategory = (id: string, params: object) => {
    $api.delete('api/main/case/category?id=' + id, {data: params}).catch(ex => detectedError(ex));
}

export const deleteCase = (id: string, params: object) => {
    $api.delete('api/main/case?id=' + id, {data: params}).catch(ex => detectedError(ex));
}

export const deleteDiagnosis = (diagnosisId: string, params: object) => {
    $api.delete('api/main/case/diagnosis?diagnosisId=' + diagnosisId, {data: params})
        .catch(ex => detectedError(ex));
}

export const updateDiagnosisReplacement = (params: object) => {
    $api.put('api/main/case/diagnosis/replacement', params)
        .catch(ex => detectedError(ex));
}

export const deleteDialogue = (dialogueId: string, params: object) => {
    $api.delete('api/main/case/dialogue?dialogueId=' + dialogueId, {data: params})
        .catch(ex => detectedError(ex));
}

export const deletePercussion = (id: string, params: object) => {
    $api.delete('api/main/case/review/percussion?percussionId=' + id, {data: params})
        .catch(ex => detectedError(ex));
}

export const deleteAuscultation = (id: string, params: object) => {
    $api.delete('api/main/case/review/auscultation?auscultationId=' + id, {data: params})
        .catch(ex => detectedError(ex));
}

export const deletePalpation = (id: string, params: object) => {
    $api.delete('api/main/case/review/palpation?palpationId=' + id, {data: params})
        .catch(ex => detectedError(ex));
}

export const deleteVisual = (id: string, params: object) => {
    $api.delete('api/main/case/review/visual?visualId=' + id, {data: params})
        .catch(ex => detectedError(ex));
}

export const deleteInstruments = (id: string, params: object) => {
    $api.delete('api/main/case/review/instruments?instrumentsId=' + id, {data: params})
        .catch(ex => detectedError(ex));
}

export const deleteAudio = (id: string, url: string, params: object) => {
    $api.delete('api/main/case/audio?id=' + id + '&url=' + url, {data: params}).catch(ex => detectedError(ex));
}

export const getDescription = async (id: string, type: string) => {
    try {
        const response = await $api.get('api/main/media/description?id=' + id + '&type=' + type);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const deleteInstrumentsImg = (id: string, url: string, params: object) => {
    $api.delete('api/main/case/review/instruments/image?id=' + id + '&url=' + url, {data: params})
        .catch(ex => detectedError(ex));
}

export const deletePercussionAudio = (id: string, url: string, params: object) => {
    $api.delete('api/main/case/review/percussion/audio?id=' + id + '&url=' + url, {data: params})
        .catch(ex => detectedError(ex));
}

export const deleteAuscultationAudio = (id: string, url: string, params: object) => {
    $api.delete('api/main/case/review/auscultation/audio?id=' + id + '&url=' + url, {data: params})
        .catch(ex => detectedError(ex));
}

export const deleteTreatment = (medicineId: string, params: object) => {
    $api.delete('api/main/case/treatment?medicineId=' + medicineId, {data: params}).catch(ex => detectedError(ex));
}

export const insertMedicine = () => {
    $api.post('api/main/medicine').catch(ex => detectedError(ex));
}

export const deleteMedicine = (medicineId: string, params: object) => {
    $api.delete('api/main/medicine?medicineId=' + medicineId, {data: params}).catch(ex => detectedError(ex));
}

export const updateMedicines = (params: object) => {
    $api.put('api/main/medicines', params).catch(ex => detectedError(ex));
}

export const updateMedicineCategory = (params: object) => {
    $api.put('api/main/medicine/category', params).catch(ex => detectedError(ex));
}

export const updateMedicineName = (params: object) => {
    $api.put('api/main/medicines/name', params).catch(ex => detectedError(ex));
}

export const updateDialogue = (params: object) => {
    $api.put('api/main/case/dialogue', params).catch(ex => detectedError(ex));
}

export const updateDialogueText = (params: object) => {
    $api.put('api/main/case/dialogue/text', params).catch(ex => detectedError(ex));
}

export const updateDialogueScore = (params: object) => {
    $api.put('api/main/case/dialogue/score', params).catch(ex => detectedError(ex));
}

export const insertDialogueTranslate = (params: object) => {
    $api.put('api/main/case/dialogue/translate', params).catch(ex => detectedError(ex));
}

export const updateDialogueDragDrop = async (params: object) => {
    try {
        const response = await $api.put('api/main/case/dialogue/dragdrop', params);
        if (response.status === 200) return true;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getDiseaseForDialogue = async () => {
    try {
        const response = await $api.get('api/main/case/disease/diagnosis');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getDisease = async () => {
    try {
        const response = await $api.get('api/main/case/disease');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateDisease = (params: object) => {
    $api.put('api/main/case/disease', params).catch(ex => detectedError(ex));
}

export const updateExaminationText = (params: object) => {
    $api.put('api/main/case/examination/text', params).catch(ex => detectedError(ex));
}

export const updateExaminationScore = (params: object) => {
    $api.put('api/main/case/examination/score', params).catch(ex => detectedError(ex));
}

export const updateExaminationTable = (params: object) => {
    $api.put('api/main/case/examination/table', params).catch(ex => detectedError(ex));
}

export const updateExaminationTabsColumns = (params: object) => {
    $api.put('api/main/case/examination/tabs/columns', params).catch(ex => detectedError(ex));
}

export const updateTreatmentHospitalization = (params: object) => {
    $api.put('api/main/case/treatment/hospitalization', params).catch(ex => detectedError(ex));
}

export const updateTreatmentDiet = (params: object) => {
    $api.put('api/main/case/treatment/diet', params).catch(ex => detectedError(ex));
}

export const insertTreatmentAddDiet = (params: object) => {
    $api.post('api/main/case/treatment/add/diet', params).catch(ex => detectedError(ex));
}

export const deleteTreatmentDelDiet = (dietId: string, params: object) => {
    $api.delete('api/main/case/treatment/delete/diet?dietId=' + dietId, {data: params})
        .catch(ex => detectedError(ex));
}

export const updateCaseJob = (params: object) => {
    $api.put('api/main/case/job', params).catch(ex => detectedError(ex));
}

export const updateCaseAge = (params: object) => {
    $api.put('api/main/case/age', params).catch(ex => detectedError(ex));
}

export const updateCaseHeightWeight = (params: object) => {
    $api.put('api/main/case/height/weight', params).catch(ex => detectedError(ex));
}

export const updateDose = (params: object) => {
    $api.put('api/main/medicine/dose', params).catch(ex => detectedError(ex));
}

export const insertDose = (params: object) => {
    $api.post('api/main/medicine/dose', params).catch(ex => detectedError(ex));
}

export const deleteDose = (params: object) => {
    $api.delete('api/main/medicine/dose', {data: params}).catch(ex => detectedError(ex));
}

export const updateCategoryNameEdit = async (params: object) => {
    try {
        await $api.put('api/main/case/category', params);
    } catch (e: any) {
        return e;
    }
}

export const updateCaseNameEdit = async (params: object) => {
    try {
        await $api.put('api/main/case/name/edit', params);
    } catch (ex: any) {
        if (ex.response.data === 'Error in data.') return ex.response.data;
        detectedError(ex);
    }
}

export const updateTreatment = (params: object) => {
    $api.put('api/main/case/treatment', params).catch(ex => detectedError(ex));
}

export const updateTreatmentScore = (params: object) => {
    $api.put('api/main/case/treatment/score', params).catch(ex => detectedError(ex));
}

export const updateTreatmentMedicines = (params: object) => {
    $api.put('api/main/case/treatment/medicines', params).catch(ex => detectedError(ex));
}

export const updateReviewText = (params: object) => {
    $api.put('api/main/case/review/text', params).catch(ex => detectedError(ex));
}

export const updateReviewScore = (params: object) => {
    $api.put('api/main/case/review/score', params).catch(ex => detectedError(ex));
}

export const updatePalpationPain = (params: object) => {
    $api.put('api/main/case/review/palpation/pain', params).catch(ex => detectedError(ex));
}

export const deleteFile = (id: string, collection: string) => {
    $api.delete('api/main/media?id=' + id + '&collection=' + collection).catch(ex => detectedError(ex));
}

export const getPathology = async () => {
    try {
        const response = await $api.get('api/main/case/pathology');
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateCasePathology = (params: object) => {
    $api.put('api/main/case/pathology', params).catch(ex => detectedError(ex));
}

export const getCasePassing = async (caseId: string, lang: string) => {
    try {
        const response = await $api.get(`api/main/case/passing?caseId=${caseId}&lang=${lang}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const updateCasePassing = async (params: object) => {
    $api.put('api/main/case/passing', params).catch(ex => detectedError(ex));
}

export const downloadCasePassing = async (caseId: string, lang: string) => {
    try {
        const response = await $api.get(`api/main/case/passing/download?caseId=${caseId}&lang=${lang}`);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const getPackCaseLeft = async (packId: string) => {
    try {
        const response = await $api.get('api/main/pack/left?packId=' + packId);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const translateGPTDialogue = async (params: object) => {
    try {
        const response = await $api.post('api/main/gpt/translate/dialogue', params);
        return response.data;
    } catch (ex) {
        return detectedError(ex);
    }
}

export const getDialoguesFiles = async (params: object) => {
    try {
        const response = await $api.post('api/main/case/dialogue/files', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const unloadExcel = async (params: object) => {
    try {
        const response = await $api.post('api/main/case/excel', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}

export const uploadingDataPack = async (params: object) => {
    try {
        const response = await $api.post('api/main/pack/uploading/data', params);
        return response.data;
    } catch (ex) {
        detectedError(ex);
    }
}